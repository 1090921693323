import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AdminLogin() {
  const navigate = useNavigate();
  const [user, setuser] = useState({
    email: "",
    password: "",
  });

  const credentails = [
    { email: "admin@labeah.com", password: '123123' },
    // { email: "saalmutairi@mos.gov.sa", password: '123123' },
    // { email: "aralajmi@mos.gov.sa", password: '123123' }
  ]
  async function login() {
    if (credentails.find(cred => cred.email === user.email && cred.password === user.password)) {
      localStorage.setItem('access', 'zfRJDUsySR')
      navigate("/admin/organizations");
    } else {
      toast.error("Incorrect credentials.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      })
    }
  }

  return (
    <div className="h-screen flex justify-center items-center bg-login-bg bg-cover bg-bottom">
      <div className="bg-white rounded-2xl w-[40rem] py-10 px-5">
        {/* <Logo className="mx-auto" /> */}
        <h1 className="text-center mt-5 text-lg font-medium mb-1">
          Welcome Admin!
        </h1>
        <h4 className="text-center text-[#8B8D97] mb-3">
          Admin Panel
        </h4>

        <div className="space-y-4 pt-7 pb-5">
          <div className="flex bg-[#EFF1F999] rounded-xl">
            <input
              className="bg-[#EFF1F999] w-full py-3 rounded-r-xl outline-none p-4"
              placeholder="Email"
              type="email"
              onChange={(e) => setuser({ ...user, email: e.target.value })}
            />
          </div>

          <div className="flex bg-[#EFF1F999] rounded-xl">
            <input
              className="bg-[#EFF1F999] w-full py-3 rounded-r-xl outline-none p-4 "
              placeholder="Password"
              type="password"
              onChange={(e) => setuser({ ...user, password: e.target.value })}
            />
          </div>
        </div>
        <button
          onClick={login}
          className="flex bg-primary mx-auto text-white rounded-xl px-7 py-2 mt-7"
        >
          Login
        </button>
      </div>

      <ToastContainer />
    </div>
  );
}
