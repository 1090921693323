import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import axios from "axios";

import Layout from "../components/common/Layout";
import { export_logs, getAllLogsAPI, getAllReportsAPI } from "../services/apis/Dashboard";
import GenericModal from "../components/common/GenericModal";
import { useContext } from "react";
import { AuthContext } from "../store/authentication";
import { formatDateDDMMYY } from "../utils/formatting";
import DatePicker from "react-datepicker";
import { constructQueryParams } from "../utils/encodeURI";
import { saveAs } from 'file-saver'

export default function Reports() {
  const { profile } = useContext(AuthContext)
  const [logs, setLogs] = useState([]);
  const [totalLogs, setTotalLogs] = useState(0);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 15,
    gte: '',
    lte: ''
  });
  const totalPages = Math.ceil(totalLogs / filter.limit);

  const [failedMsgs, setfailedMsgs] = useState([]);
  const [FreqMsgs, setFreqMsgs] = useState([]);
  const [openModal, setopenModal] = useState(false);
  const [selectedItem, setselectedItem] = useState({});
  const [correction, setcorrection] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false)

  const handleOnDateChange = (dates) => {
    console.log('dates', dates)
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      setFilter((previous) => {
        return {
          ...previous,
          gte: formatDateDDMMYY(start),
          lte: formatDateDDMMYY(end),
        }
      })
    }
  }


  async function get_all_logs() {
    console.log('get_all_logs', filter)
    await getAllLogsAPI(profile.organization._id, filter)
      .then((res) => {
        setLogs(res.data.data);
        setTotalLogs(res.data.total);
      })
      .catch((err) => console.log(err));
  }

  async function get_all_reports() {
    console.log('get_all_reports', filter)
    await getAllReportsAPI(profile.organization._id, filter)
      .then((res) => {
        // setfailedMsgs(res.data.result[0]["top_failed_messages"]); temp
        setFreqMsgs(res.data.result[0]["top_frequency_messages"]);
      })
      .catch((err) => console.log(err));
  }

  async function correct_answer() {
    try {
      const response = await axios.post(
        "https://qiwa-api.labeah.ai/logs/upload/fallback",
        {
          lang: "ar",
          question: selectedItem.message_in,
          answer: correction,
        }
      );

      // console.log("Response from API:", response.data);
      setopenModal(false);
      get_all_reports();
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  }

  useEffect(() => {
    get_all_reports();
  }, [filter]);

  useEffect(() => {
    get_all_logs();
  }, [filter]);

  // Handle page change
  const handlePageChange = (selectedItem) => {
    setFilter((prev) => ({ ...prev, page: selectedItem.selected + 1 }));
  };

  const handleOnExport = (fileName = "data.xlsx") => {
    setLoading(true)
    const query = constructQueryParams(filter)
    export_logs(profile.organization._id, query).then((response) => {
      setLoading(false)
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, fileName);
    }).catch((error) => {
      setLoading(false)
      console.log('something went wrong', error)
    })

  }

  return (
    <Layout header="Reports">
      <div className="p-6">
        <div className="flex justify-end gap-5 items-center mb-4">
          <div className="flex bg-[#EFF1F9] rounded-lg ">
            <DatePicker dateFormat="dd-MM-yyyy" selectsRange placeholderText="Date Filter" className="bg-white w-full p-2  border rounded-lg" onChange={handleOnDateChange} startDate={startDate} endDate={endDate} />
          </div>
          <button className="bg-primary text-white px-5 py-2 rounded-lg flex items-center" onClick={() => handleOnExport()}>
            {loading ?
              <>
                Loading
                <svg class="animate-spin h-5 w-5 ml-3 bg-transparent p-2 border-t border-r border-white rounded-full" viewBox="0 0 24 24"></svg>

              </>
              :

              "Export"}
          </button>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className="ring-1 ring-gray-200 sm:mx-0 rounded-lg bg-white p-5">
            <h1 className="text-xl mb-3">Top Frequent Messages</h1>
            <MiniTable data={FreqMsgs} hasActions={false} />
          </div>
          <div className="ring-1 ring-gray-200 sm:mx-0 rounded-lg bg-white p-5">
            <h1 className="text-xl mb-3">Top Failed Messages</h1>
            <MiniTable
              data={failedMsgs}
              hasActions={true}
              setopenModal={setopenModal}
              setselectedItem={setselectedItem}
            />
          </div>
        </div>
        <br />
        <div>
          <h1 className="text-2xl mb-3">Logs</h1>
          <Table data={logs} />
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName="flex justify-center mt-4 space-x-2"
            activeClassName="text-blue-600 font-bold"
            pageClassName="px-3 py-1 border border-gray-300 rounded bg-white"
            previousLabel="<"
            nextLabel=">"
            previousClassName="px-3 py-1 border border-gray-300 rounded bg-white"
            nextClassName="px-3 py-1 border border-gray-300 rounded bg-white"
            disabledClassName="text-gray-300"
          />
        </div>
      </div>

      <GenericModal
        title="Answer correction"
        open={openModal}
        setOpen={setopenModal}
        func={() => correct_answer()}
      >
        <div>
          <label className="text-sm">Failed Message</label>
          <input
            value={selectedItem.message_in}
            disabled
            className="bg-[#EFF1F999] w-full p-2 rounded-xl outline-none mt-1"
          />
        </div>
        <br />

        <div>
          <label className="text-sm">Correct Answer</label>
          <input
            className="bg-white border w-full p-2 rounded-xl outline-none mt-1"
            placeholder="Enter correct answer here..."
            onChange={(e) => setcorrection(e.target.value)}
          />
        </div>
      </GenericModal>
    </Layout>
  );
}

function Table({ data }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="ring-1 ring-gray-200 sm:mx-0 sm:rounded-lg bg-white">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              IP Address
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Message in
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Message out
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Status
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Latency
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Source
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, itemIdx) => (
            <tr key={itemIdx}>
              <td
                className={classNames(
                  itemIdx === 0 ? "" : "border-t border-gray-200",
                  "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                )}
              >
                <div className="font-medium text-gray-900">
                  {item.ip_address}
                </div>
              </td>
              <td
                className={classNames(
                  itemIdx === 0 ? "" : "border-t border-gray-200",
                  "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                )}
              >
                {item.message_in}
              </td>
              <td
                className={classNames(
                  itemIdx === 0 ? "" : "border-t border-gray-200",
                  "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                )}
              >
                {item.message_out}
              </td>
              <td
                className={classNames(
                  itemIdx === 0 ? "" : "border-t border-gray-200",
                  `hidden px-3 py-3.5 text-sm lg:table-cell ${item.status === "Success"
                    ? "text-green-600"
                    : "text-red-600"
                  }`
                )}
              >
                {item.status}
              </td>
              <td
                className={classNames(
                  itemIdx === 0 ? "" : "border-t border-gray-200",
                  "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                )}
              >
                {item.latency}
              </td>
              <td
                className={classNames(
                  itemIdx === 0 ? "" : "border-t border-gray-200",
                  "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                )}
              >
                {item.source}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function MiniTable({ data, hasActions, setopenModal, setselectedItem }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="ring-1 ring-gray-200 sm:mx-0 sm:rounded-lg bg-white">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Message in
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Frequencies
            </th>
            {hasActions ? (
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Action
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((item, itemIdx) => (
              <tr key={itemIdx}>
                <td
                  className={classNames(
                    itemIdx === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                  )}
                >
                  <div className="font-medium text-gray-900">
                    {item.message_in}
                  </div>
                </td>
                <td
                  className={classNames(
                    itemIdx === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                  )}
                >
                  {item.frequency}
                </td>
                {hasActions ? (
                  <td
                    className={classNames(
                      itemIdx === 0 ? "" : "border-t border-gray-200",
                      "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell "
                    )}
                  >
                    <ArrowTopRightOnSquareIcon
                      onClick={() => {
                        setopenModal(true);
                        setselectedItem(item);
                      }}
                      className="h-5 w-5 hover:cursor-pointer"
                    />
                  </td>
                ) : null}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={2}
                className="px-3 py-3.5 text-sm text-gray-500 text-center"
              >
                No Records
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
