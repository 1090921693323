import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import {
    ExclamationTriangleIcon,
    XMarkIcon,
    PencilSquareIcon
} from "@heroicons/react/24/outline";

export default function EditModal({ open, setOpen, title, update, setUpdate, func }) {

    const handleOnInputChange = (event) => {
        setUpdate((previous) => ({ ...previous, [event.target.name]: event.target.value }))
    }

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-50 w-full">
            <DialogBackdrop
                transition
                className="w-full fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto w-full">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 w-full">
                    <DialogPanel
                        transition
                        className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                    >
                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                            <button
                                type="button"
                                onClick={() => setOpen(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>
                        <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-200 sm:mx-0 sm:h-10 sm:w-10">
                                <PencilSquareIcon
                                    aria-hidden="true"
                                    className="h-6 w-6 text-primary"
                                />
                            </div>
                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full mb-5">
                                <DialogTitle
                                    as="h3"
                                    className="text-base font-semibold leading-6 text-gray-900"
                                >
                                    Edit {title}
                                </DialogTitle>


                                <div className="flex gap-5 my-5">
                                    <div className="grow w-full">
                                        <label className="text-sm text-[#5E6366]">
                                            Name
                                        </label>
                                        <div className="flex bg-[#EFF1F9] rounded-lg mt-2">
                                            <input
                                                name="name"
                                                onChange={handleOnInputChange}
                                                value={update.name}
                                                className="bg-[#EFF1F9] w-full py-3 pl-3 outline-none rounded-lg"
                                                placeholder="Enter tenant name here..."
                                            />
                                        </div>
                                    </div>

                                    <div className="">
                                        <label className="text-sm text-[#5E6366]">
                                            Quote
                                        </label>
                                        <div className="flex bg-[#EFF1F9] rounded-lg mt-2">
                                            <input
                                                type="number"
                                                name="quote"
                                                value={update.quote}
                                                onChange={handleOnInputChange}
                                                className="bg-[#EFF1F9] w-full py-3 pl-3 outline-none rounded-lg"
                                                placeholder="Enter tenant quote here..."
                                            />
                                        </div>
                                    </div>


                                </div>


                                <div className="">
                                    <label className="text-sm text-[#5E6366]">Logo</label>
                                    <div className="flex bg-[#EFF1F9] rounded-lg mt-2">
                                        <input
                                            name="logo"
                                            value={update.logo}
                                            onChange={handleOnInputChange}
                                            className="bg-[#EFF1F9] w-full py-3 pl-3 outline-none rounded-lg"
                                            placeholder="Enter logo here..."
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                onClick={() => func()}
                                className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                            >
                                Edit
                            </button>
                            <button
                                type="button"
                                onClick={() => setOpen(false)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
