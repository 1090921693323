import { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment";
import Layout from "../components/common/Layout";
import { AuthContext } from "../store/authentication";
import { BASE_URL } from "../constants/config";
import { formatDateDDMMYY } from "../utils/formatting";
import { constructQueryParams } from "../utils/encodeURI";
import DatePicker from "react-datepicker";

export default function Reviews() {
  const { profile } = useContext(AuthContext)

  const [convs, setconvs] = useState([]);
  const [data, setdata] = useState([]);


  const [conversations, setConversations] = useState({ data: [], total: 0, count: 0 })
  const [filters, setFilters] = useState({ gte: '', lte: '', page: 1, limit: 15, })
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleOnDateChange = (dates) => {
    console.log('dates', dates)
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      setFilters((previous) => {
        return {
          ...previous,
          gte: formatDateDDMMYY(start),
          lte: formatDateDDMMYY(end)
        }
      })
    }
  }

  const handleOnInputChange = (event) => {
    setFilters({ ...filters, [event.target.id]: event.target.value })
  }

  const handlePageChange = (selectedItem) => {
    setFilters((prev) => ({ ...prev, page: selectedItem.selected + 1 }));
  };


  async function get_all_conversations() {
    try {
      const query = constructQueryParams(filters);
      const response = await axios.get(
        `${BASE_URL}/api/v1/reviews/conversation/${profile.organization._id}?${query}`
        //  `${BASE_URL}/api/v1/reviews/conversation/${profile.organization._id}`
      );
      setconvs(response.data.data);
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  }

  async function get_all_data() {
    try {
      const query = constructQueryParams(filters);
      const response = await axios.get(
        `${BASE_URL}/api/v1/reviews/message/${profile.organization._id}?${query}s`
      );

      console.log('resresres', response)
      setdata(response.data.data);
      console.log("Response from get_all_data:", response);
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  }

  useEffect(() => {
    get_all_conversations();
    get_all_data();
  }, [filters]);

  return (
    <Layout header="Reviews">
      <div className="p-6">
        <div className="flex justify-between gap-5 items-center">
          <h1>Analytics</h1>
          <div className="flex bg-[#EFF1F9] rounded-lg  mb-4">
            <DatePicker dateFormat="dd-MM-yyyy" selectsRange placeholderText="Date Filter" className="bg-white  w-full p-2  border rounded-lg" onChange={handleOnDateChange} startDate={startDate} endDate={endDate} />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className="ring-1 ring-gray-200 sm:mx-0 rounded-lg bg-white p-5">
            <h1 className="text-xl mb-3">Conversations reviews</h1>
            <ConvsTable
              headers={["conversation id", "review", "rate", "created on"]}
              data={convs}
            />
          </div>
          <div className="ring-1 ring-gray-200 sm:mx-0 rounded-lg bg-white p-5">
            <h1 className="text-xl mb-3">Likes and dislikes</h1>
            <DataTable
              headers={["message", "is_liked", "user_feedback", "created_on"]}
              data={data}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

function ConvsTable({ headers, data }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="ring-1 ring-gray-200 sm:mx-0 sm:rounded-lg bg-white">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {headers.map((item, index) => {
              return (
                <th
                  key={index}
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((item, itemIdx) => (
              <tr key={itemIdx}>
                <td
                  className={classNames(
                    itemIdx === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                  )}
                >
                  <div className="font-medium text-gray-900">
                    {item.conversation_id}
                  </div>
                </td>
                <td
                  className={classNames(
                    itemIdx === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                  )}
                >
                  {item.review}
                </td>
                <td
                  className={classNames(
                    itemIdx === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                  )}
                >
                  {item.rate}
                </td>
                <td
                  className={classNames(
                    itemIdx === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                  )}
                >
                  {moment(item.created_on).format("DD/MM/YYYY")}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={2}
                className="px-3 py-3.5 text-sm text-gray-500 text-center"
              >
                Table is empty
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

function DataTable({ headers, data }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="ring-1 ring-gray-200 sm:mx-0 sm:rounded-lg bg-white">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {headers.map((item, index) => {
              return (
                <th
                  key={index}
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((item, itemIdx) => (
              <tr key={item.id}>
                <td
                  className={classNames(
                    itemIdx === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                  )}
                >
                  <div className="font-medium text-gray-900">
                    {item.message}
                  </div>
                </td>
                <td
                  className={classNames(
                    itemIdx === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                  )}
                >
                  {item?.is_liked?.toString()}
                </td>
                <td
                  className={classNames(
                    itemIdx === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                  )}
                >
                  {item.user_feedback}
                </td>
                <td
                  className={classNames(
                    itemIdx === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                  )}
                >
                  {moment(item.created_on).format("DD/MM/YYYY")}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={2}
                className="px-3 py-3.5 text-sm text-gray-500 text-center"
              >
                Table is empty
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
