import { useEffect, useState } from "react";
import Layout from "./Layout";
import { latest_logs, read } from "../../constants/apis/Analytics";
import { read as readAllOrganizations } from '../../constants/apis/Organizations'
import { ReactComponent as User } from "../../assets/icons/User.svg";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateDDMMYY } from "../../utils/formatting";
import { constructQueryParams } from "../../utils/encodeURI";
import ReactPaginate from "react-paginate";

export default function Analytics() {
    const [analytics, setAnalytics] = useState()
    const [filters, setFilters] = useState({ gte: '', lte: '', organization: '', searchQuery: '', page: 1, limit: 15, })
    const [organizations, setOrganizations] = useState([])
    const [logs, setLogs] = useState({ data: [], total: 0, count: 0 })
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)

    const handleOnDateChange = (dates) => {
        console.log('dates', dates)
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (start && end) {
            setFilters((previous) => {
                return {
                    ...previous,
                    gte: formatDateDDMMYY(start),
                    lte: formatDateDDMMYY(end)
                }
            })
        }
    }

    const handleOnInputChange = (event) => {
        setFilters({ ...filters, [event.target.id]: event.target.value })
    }

    const handlePageChange = (selectedItem) => {
        setFilters((prev) => ({ ...prev, page: selectedItem.selected + 1 }));
    };

    useEffect(() => {
        const query = constructQueryParams(filters);
        read(query).then((response) => {
            console.log('anlytics', response)
            if (response) {
                setAnalytics(response)
            }
        }).catch((error) => {
            console.log('error', error)
        })
    }, [filters.gte, filters.lte, filters.organization])


    useEffect(() => {
        setLoading(true)
        const query = constructQueryParams(filters)
        latest_logs(query).then((response) => {
            console.log('response', response)
            setLoading(false)
            if (response) {
                setLogs((previous) => {
                    return {
                        ...previous,
                        data: response.data || [],
                        total: response.total || 0,
                        count: response.count || 0
                    }
                })
            }
        }).catch((error) => {
            setLoading(false)
            console.log('error', error)
        })
    }, [filters])


    useEffect(() => {
        readAllOrganizations().then((response) => {
            if (response.length > 0) {
                setOrganizations(response.map((organization) => ({ text: organization.name, value: organization._id })))
            } else {
                setOrganizations([])
            }
        }).catch((error) => {
            console.log('error', error)
            setOrganizations([])
        })
    }, [])

    return (
        <Layout header="Dashboard" >
            <div className="h-4"></div>
            <div className="p-10 bg-white rounded-lg border shadow-sm m-10">
                <div className="flex justify-between items-center mb-10">
                    <p className="text-xl w-full font-normal text-primary  align-text-bottom ">System Analytics</p>

                    <div className="flex gap-5 w-full justify-end items-center">

                        <div className="flex bg-[#EFF1F9] rounded-lg w-full">
                            <DatePicker dateFormat="dd-MM-yyyy" selectsRange placeholderText="Date Range" className="bg-[#EFF1F9] w-full p-3   rounded-lg" onChange={handleOnDateChange} startDate={startDate} endDate={endDate} />
                        </div>
                        <div className="flex bg-[#EFF1F9] rounded-lg  w-full ">
                            <select className="bg-[#EFF1F9] w-full p-3 rounded-lg" value={filters.organization} id='organization' name='organization' onChange={handleOnInputChange}>
                                <option disabled={true} value=''>Organization</option>
                                {organizations.map((organization) => (<option key={organization.value} value={organization.value} >{organization.text}</option>))}
                            </select>
                        </div>
                    </div>

                </div>

                <div className="flex gap-5 mb-5">
                    <div className="bg-red-50 h-32 w-1/2 shadow-sm rounded-lg flex flex-col justify-center items-center border">
                        <p className="text-2xl font-bold mb-2">{`${analytics?.conversations.toLocaleString()} / ${analytics?.quotes.toLocaleString()}`}</p>
                        <p className="text-md font-bold text-gray-500">Consumption</p>
                    </div>
                    {filters.organization ?
                        <div className="bg-gray-100 h-32 w-1/4 shadow-sm rounded-lg flex flex-col justify-center items-center border">
                            <p className="text-md font-bold mb-2">{analytics?.organizations.name}</p>
                        </div>
                        :
                        <div className="bg-gray-100 h-32 w-1/4 shadow-sm rounded-lg flex flex-col justify-center items-center border">
                            <p className="text-3xl font-bold mb-2">{analytics?.organizations.toLocaleString()}</p>
                            <p className="text-md font-bold text-gray-500">Organizations</p>
                        </div>
                    }

                    <div className="bg-gray-100 h-32 w-1/4 shadow-sm rounded-lg flex flex-col justify-center items-center border">
                        <p className="text-3xl font-bold mb-2">{analytics?.admins.toLocaleString()}</p>
                        <p className="text-md font-bold text-gray-500">Admins</p>
                    </div>
                </div>



                <div className="flex gap-5 mb-5">
                    <div className="bg-gray-100 h-32 w-1/4 shadow-sm rounded-lg flex flex-col justify-center items-center border">
                        <p className="text-3xl font-bold mb-2">{analytics?.logs.toLocaleString()}</p>
                        <p className="text-md font-bold text-gray-500">Api Hits</p>
                    </div>
                    <div className="bg-gray-100 h-32 w-1/4 shadow-sm rounded-lg flex flex-col justify-center items-center border">
                        <p className="text-3xl font-bold mb-2">{analytics?.conversations.toLocaleString()}</p>
                        <p className="text-md font-bold text-gray-500">Conversations</p>
                    </div>
                    <div className="bg-gray-100 h-32 w-1/2 shadow-sm rounded-lg flex flex-col justify-center items-center border">
                        <p className="text-3xl font-bold mb-2">{analytics?.quotes.toLocaleString()}</p>
                        <p className="text-md font-bold text-gray-500">quotes</p>
                    </div>

                </div>

                <div className="flex gap-5">
                    <div className="bg-gray-100 h-32 w-1/4 shadow-sm rounded-lg flex flex-col justify-center items-center border">
                        <p className="text-3xl font-bold mb-2">{analytics?.latency.toLocaleString()} Sec</p>
                        <p className="text-md font-bold text-gray-500">Response Time</p>
                    </div>
                </div>
            </div>

            <div className="p-10 bg-white rounded-lg border shadow-sm m-10">
                <div className="flex items-center justify-between mb-5">
                    <div>
                        <h1 className="text-2xl mb-1">Latest Logs ({logs.total})</h1>
                        <p className="text-gray-700 text-md ml-2">{filters.organization ? analytics?.organizations.name : 'All Organizations'}</p>
                    </div>

                    <div className="flex bg-[#EFF1F9] rounded-lg mt-2 space-x-2">
                        <input
                            id="searchQuery"
                            onChange={(event) => { setSearch(event.target.value) }}
                            value={search}
                            className="bg-[#EFF1F9] w-full py-3 px-5 outline-none rounded-lg"
                            placeholder="Search Here."
                        />
                        <button onClick={() => setFilters((previous) => ({ ...previous, searchQuery: search }))} className="py-3 bg-primary rounded-lg text-white px-5 flex gap-2 items-center">
                            {loading ?
                                <>Loading<svg class="animate-spin h-5 w-5 ml-3 bg-transparent p-2 border-t border-r border-white rounded-full" viewBox="0 0 24 24"></svg></>
                                :
                                "Search"
                            }
                        </button>
                    </div>
                </div>

                {!loading &&
                    <>
                        <Table data={logs.data} />
                        <ReactPaginate
                            pageCount={Math.ceil(logs.total / logs.count)}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName="flex justify-center mt-4 space-x-2"
                            activeClassName="text-blue-600 font-bold"
                            pageClassName="px-3 py-1 border border-gray-300 rounded bg-white"
                            previousLabel="<"
                            nextLabel=">"
                            previousClassName="px-3 py-1 border border-gray-300 rounded bg-white"
                            nextClassName="px-3 py-1 border border-gray-300 rounded bg-white"
                            disabledClassName="text-gray-300"
                        />
                    </>
                }
            </div>

        </Layout>
    )
}

function Table({ data }) {
    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }
    return (
        <div className="ring-1 ring-gray-200 sm:mx-0 sm:rounded-lg bg-white">
            <table className="min-w-full divide-y divide-gray-200">
                <thead>
                    <tr>
                        <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                            IP Address
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                            Message in
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                            Message out
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                            Status
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                            Latency
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                            Source
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, itemIdx) => (
                        <tr key={itemIdx}>
                            <td
                                className={classNames(
                                    itemIdx === 0 ? "" : "border-t border-gray-200",
                                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell text-center"
                                )}
                            >
                                <div className="font-medium text-gray-900">
                                    {item.ip_address}
                                </div>
                            </td>
                            <td
                                className={classNames(
                                    itemIdx === 0 ? "" : "border-t border-gray-200",
                                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                )}
                            >
                                {item.message_in}
                            </td>
                            <td
                                className={classNames(
                                    itemIdx === 0 ? "" : "border-t border-gray-200",
                                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                )}
                            >
                                {item.message_out}
                            </td>
                            <td
                                className={classNames(
                                    itemIdx === 0 ? "" : "border-t border-gray-200",
                                    `hidden px-3 py-3.5 text-sm lg:table-cell ${item.status === "Success"
                                        ? "text-green-600"
                                        : "text-red-600"
                                    }`
                                )}
                            >
                                {item.status}
                            </td>
                            <td
                                className={classNames(
                                    itemIdx === 0 ? "" : "border-t border-gray-200",
                                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                )}
                            >
                                {item.latency}
                            </td>
                            <td
                                className={classNames(
                                    itemIdx === 0 ? "" : "border-t border-gray-200",
                                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                )}
                            >
                                {item.source}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}