import React, { useState, useEffect, useContext } from "react";
import Layout from "../components/common/Layout";
import { getBillingAPI, getBillingChart } from "../services/apis/Dashboard";
import { AuthContext } from "../store/authentication";
import { Line } from "react-chartjs-2";

import { Chart as ChartJS, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend, } from 'chart.js';
import DatePicker from "react-datepicker";
import { formatDateDDMMYY } from "../utils/formatting";
import { constructQueryParams } from "../utils/encodeURI";
ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend);


export default function Billing() {
  const { profile } = useContext(AuthContext)
  const [chartData, setChartData] = useState(null);
  const [filters, setFilters] = useState({ gte: '', lte: '' })
  const [stats, setstats] = useState([
    {
      name: "Consumption",
      stat: "200,000",
      previousStat: "0",
    },
  ]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleOnDateChange = (dates) => {
    console.log('dates', dates)
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      setFilters((previous) => {
        return {
          ...previous,
          gte: formatDateDDMMYY(start),
          lte: formatDateDDMMYY(end)
        }
      })
    }
  }

  async function get_billing() {
    const result = await getBillingAPI(profile.organization._id);
    setstats([
      {
        name: "Consumption",
        stat: profile.organization.quote,
        previousStat: result.data.consumption_quota,
      },
    ]);
  }


  async function get_billing_chart() {
    const query = constructQueryParams(filters);
    const response = await getBillingChart(profile.organization._id, query);
    console.log('respdonse', response)

    const data = response.data.data;

    const labels = data.map(item => item._id); // Dates
    const counts = data.map(item => item.count); // Counts

    setChartData({
      labels,
      datasets: [
        {
          label: 'Consumption Per Day',
          data: counts,
          // borderColor: 'rgba(75, 192, 192, 1)',
          // backgroundColor: 'rgba(75, 192, 192, 0.2)',
          // tension: 0.4,

          borderColor: '#6366F1', // Indigo color for bg-indigo-500
          backgroundColor: 'rgba(99, 102, 241, 0.2)', // Slightly transparent indigo for background
            tension: 0.4,
  
        },
      ],
    })
  }

  useEffect(() => {
    get_billing();
    get_billing_chart()

  }, [filters]);

  return (
    <Layout header="Billing">
      <div className="p-6">
        <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-1 md:divide-x md:divide-y-0 max-w-sm">
          {stats.map((item) => (
            <div key={item.name} className="px-4 py-5 sm:p-6">
              <dt className="text-base font-normal text-gray-900">
                {item.name}
              </dt>
              <dd className="mt-3 flex items-baseline justify-between md:block lg:flex">
                <div className="flex items-baseline text-2xl font-semibold text-red-600">
                  {item.previousStat}
                  <span className="ml-2 text-xl font-medium text-gray-500">
                    / {item.stat}
                  </span>
                </div>
              </dd>
            </div>
          ))}
        </dl>

        <div>
          <div className="flex rounded-lg  my-5">
            <DatePicker dateFormat="dd-MM-yyyy" selectsRange placeholderText="Date Filter" className="bg-white  w-full p-2  border rounded-lg" onChange={handleOnDateChange} startDate={startDate} endDate={endDate} />
          </div>

          <div className="w-1/2 bg-white p-10 rounded-lg shadow-sm my-5">
            {chartData &&
              <Line data={chartData} />
            }
          </div>
        </div>
      </div>
    </Layout>
  );
}
