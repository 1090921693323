import axios from "axios"
import { BASE_URL } from "../config"


export const create = async (body) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/v1/organizations`, body, {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const read = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api/v1/organizations`, {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}


export const readOne = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/v1/organizations/read/${id}`, {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const suspend_continue = async (id) => {
    try {
        const response = await axios.put(`${BASE_URL}/api/v1/organizations/suspend_continue/${id}`, {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const remove = async (id) => {
    try {
        const response = await axios.delete(`${BASE_URL}/api/v1/organizations/delete/${id}`, {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const update = async (id, body) => {
    try {
        const response = await axios.put(`${BASE_URL}/api/v1/organizations/${id}`, body, {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

