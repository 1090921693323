import instance from "../../utils/publicInstance";
import {
  API_GET_ALL_LOGS,
  API_GET_ALL_DASHBOARD_STATS,
  API_GET_ALL_REPORTS,
  API_GET_ALL_CONVERSATIONS,
  API_GET_BILLING,
} from "../../constants/apis/Dashboard";
import axios from "axios";
import { BASE_URL } from "../../constants/config";

const getAllLogsAPI = async (orgId, params) => {
  return await instance({
    ...API_GET_ALL_LOGS,
    url: `${API_GET_ALL_LOGS.url}/${orgId}`,
    params,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => console.log(e));
};


export const export_logs = async (organization, query) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/logs/export/organization/${organization}?${query}`, {
      responseType: 'blob',
    })
    console.log('responsehre', response)
    return response
  } catch (error) {
    console.log('error', error)
    throw (error.response.data)
  }
}

// const getAllDashboardStatsAPI = async (orgId, params) => {
//   return await instance({
//     ...API_GET_ALL_DASHBOARD_STATS,
//     url: `${API_GET_ALL_DASHBOARD_STATS.url}/${orgId}?${params}`,
//   })
//     .then((res) => {
//       return res;
//     })
//     .catch((e) => console.log(e));
// };

const getAllDashboardStatsAPI = async (orgId, query) => {
  try {
    console.log('query', query)
    const response = await axios.get(`${BASE_URL}/api/v1/logs/dashboard/organization/${orgId}?${query}`, {
      headers: { 'Content-Type': 'application/json' }
    })
    return response
  } catch (error) {
    console.log('error', error)
    throw (error.response.data)
  }
}


const getAllReportsAPI = async (orgId, params) => {
  return await instance({
    ...API_GET_ALL_REPORTS,
    url: `${API_GET_ALL_REPORTS.url}/${orgId}`,
    params,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => console.log(e));
};

const getAllConversationsAPI = async (orgId, params) => {
  return await instance({
    ...API_GET_ALL_CONVERSATIONS,
    url: `${API_GET_ALL_CONVERSATIONS.url}/${orgId}`,
    params,
  })
    .then((res) => {
      console.log('res', res)
      return res;
    })
    .catch((e) => console.log(e));
};

const getBillingAPI = async (orgId, params) => {
  return await instance({
    ...API_GET_BILLING,
    url: `${API_GET_BILLING.url}/${orgId}`,
    params,
  })
    .then((res) => {
      console.log('res', res)
      return res;
    })
    .catch((e) => console.log(e));
}

const getBillingChart = async (orgId, query) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/logs/quota/organization/${orgId}/visualization?${query}`, {
      headers: { 'Content-Type': 'application/json' }
    })
    console.log('response', response)
    if (response.status === 200) {
      return response
    }
    return response
  } catch (error) {
    console.log('error', error)
    throw (error.response.data)
  }
}


// const getBillingAPI = async (orgId) => {
//   return await instance({ ...API_GET_BILLING, url: `${API_GET_ALL_CONVERSATIONS.url}/${orgId}`, })
//     .then((res) => {
//       console.log('res', res)
//       return res;
//     })
//     .catch((e) => console.log(e));
// };

export {
  getAllLogsAPI,
  getAllDashboardStatsAPI,
  getAllReportsAPI,
  getAllConversationsAPI,
  getBillingAPI,
  getBillingChart
};
