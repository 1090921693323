import React, { useEffect, useState } from "react";
import Layout from "../components/common/Layout";
import { getAllConversationsAPI } from "../services/apis/Dashboard";
import {
  ChevronRightIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import { useContext } from "react";
import { AuthContext } from "../store/authentication";
import { formatDateDDMMYY } from "../utils/formatting";
import DatePicker from "react-datepicker";

export default function Conversations() {
  const { profile } = useContext(AuthContext)
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedItem, setSelectedItem] = useState({});
  const [filter, setFilter] = useState({ page: 1, limit: 6, gte: '', lte: '', is_liked: '' });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleOnDateChange = (dates) => {
    console.log('dates', dates)
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      setFilter((previous) => {
        return {
          ...previous,
          gte: formatDateDDMMYY(start),
          lte: formatDateDDMMYY(end),
          page: 1
        }
      })
    }
  }


  const handleOnLikeFilter = (status) => {
    setFilter((previous) => {
      return {
        ...previous,
        is_liked: status,
        page: 1
      }
    })
  }

  async function get_all_conversations() {
    await getAllConversationsAPI(profile.organization._id, filter)
      .then((res) => {
        setSelectedItem(res.data.data[0]);
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    get_all_conversations();
  }, [filter]);

  const totalPages = Math.ceil(total / filter.limit);

  const handleNextPage = () => {
    if (filter.page < totalPages) {
      setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const handlePrevPage = () => {
    if (filter.page > 1) {
      setFilter((prev) => ({ ...prev, page: prev.page - 1 }));
    }
  };

  return (
    <Layout header="Conversations">
      <div className="h-5"></div>
      <div className="flex justify-end gap-5 items-center mx-6">
        <div className="flex bg-[#EFF1F9] rounded-lg ">
          <DatePicker dateFormat="dd-MM-yyyy" selectsRange placeholderText="Date Filter" className="bg-white w-full p-2  border rounded-lg" onChange={handleOnDateChange} startDate={startDate} endDate={endDate} />
        </div>

        <div class="inline-flex rounded-md shadow-sm" role="group">


          <button onClick={() => handleOnLikeFilter(true)} type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-primary focus:z-10 focus:ring-2 focus:ring-primary focus:text-primary">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 mx-1">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z" />
            </svg>
            Like
          </button>

          <button onClick={() => handleOnLikeFilter(false)} type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-r border-gray-200  hover:bg-gray-100 hover:text-red-700 focus:z-10 focus:ring-2 focus:ring-red-700 focus:text-red-700">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 mx-1">
              <path stroke-linecap="round" stroke-linejoin="round" d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54" />
            </svg>
            Dislike
          </button>

          <button onClick={() => handleOnLikeFilter('')} type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-red-700 focus:z-10 focus:ring-2 focus:ring-red-700 focus:text-red-700">
            All
          </button>
        </div>

      </div>
      <div className="flex h-[90vh] p-6 space-x-5">
        <div className="w-[30rem] bg-white ring-1 ring-gray-200 p-5 rounded-lg">
          <h1 className="font-medium">All Conversations</h1>
          <div className="overflow-y-auto h-[70vh]">
            {data.map((item, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() => setSelectedItem(item)}
                  className={`bg-gray-50 my-3 p-3 rounded-lg cursor-pointer ${selectedItem._id === item._id
                    ? "border border-primary"
                    : "border"
                    }`}
                >
                  <div className="flex justify-between">
                    <div className="flex space-x-3">
                      <img
                        src="https://static.vecteezy.com/system/resources/previews/009/292/244/non_2x/default-avatar-icon-of-social-media-user-vector.jpg"
                        className="h-10 w-10 rounded-full m-auto"
                      />
                      <div>
                        <h1 className="font-bold">{item.ip_address}</h1>
                        <h1>{item.country}</h1>
                        <p className="flex text-sm">
                          <span className="font-bold mr-1">Created at </span>
                          {moment(item.created_on).format("DD/MM/YYYY")}
                        </p>
                        <p className="flex text-sm">
                          <span className="font-bold mr-1">Total </span>
                          {selectedItem?.conversation?.length}
                        </p>
                        <p className="flex text-sm">
                          <span className="font-bold mr-1">
                            Session Duration{" "}
                          </span>
                          {Math.floor(Math.random() * 8) + 1}m
                        </p>
                      </div>
                    </div>
                    <ChevronRightIcon className="h-5 w-5 my-auto" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-between mt-4">
            <button
              onClick={handlePrevPage}
              disabled={filter.page === 1}
              className={`relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 ${filter.page === 1 ? "opacity-50 cursor-not-allowed" : ""
                }`}
            >
              Previous
            </button>
            <span className="px-4 py-2">
              Page {filter.page} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={filter.page === totalPages}
              className={`relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 ${filter.page === totalPages
                ? "opacity-50 cursor-not-allowed"
                : ""
                }`}
            >
              Next
            </button>
          </div>
        </div>

        {/* Conversations */}
        <div className="w-full bg-white ring-1 ring-gray-200 p-5 rounded-lg">
          <div className="overflow-auto">
            {selectedItem?.conversation?.length > 0 ? (
              selectedItem.conversation.map((item, index) => (
                <div
                  key={index}
                  className={`py-2 ${item.by === "user" ? "bg-gray-100" : "bg-white"
                    }`}
                >
                  <div className="flex max-w-5xl mx-auto">
                    <img
                      src={
                        item.by === "llm"
                          ? "https://marketplace.mypurecloud.com/add4eee6-9406-448c-9ff7-6d1065338f2a/applogo_f4652212.jpg"
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                      }
                      className="h-12 w-12 rounded-lg my-auto mx-2 object-cover"
                    />
                    <div className="mx-1 w-full p-3 rounded-lg">
                      {item.text}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-400 py-10">
                No chats yet. Start a conversation!
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
