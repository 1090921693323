import axios from "axios"
import { BASE_URL } from "../config"


export const read = async (query) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/v1/analytics?${query}`, {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}


export const latest_logs = async (query) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/v1/analytics/logs?${query}`, {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}