import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";
import Reports from "./pages/Reports";
import Billing from "./pages/Billing";
import Conversations from "./pages/Conversations";
import DataManager from "./pages/DataManager";
import Reviews from "./pages/Reviews";
import Organizations from "./pages/admin/Organizations";
import AdminLogin from "./pages/admin/AdminLogin";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./store/authentication";
import Analytics from "./pages/admin/Analytics";

export default function App() {
  const { profile } = useContext(AuthContext)

  return (
    <Routes>
      <Route path="/" element={<Login />} />

      <Route path="/dashboard" element={profile ? <Dashboard /> : <Navigate to="/" />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/conversations" element={<Conversations />} />
      <Route path="/billing" element={<Billing />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/data-manager" element={<DataManager />} />
      <Route path="/reviews" element={<Reviews />} />


      <Route path="/admin" element={<AdminLogin />} />
      <Route path="/admin/organizations" element={localStorage.getItem('access') === 'zfRJDUsySR' ? <Organizations /> : <Navigate to="/admin" />} />
      <Route path="/admin/dashboard" element={localStorage.getItem('access') === 'zfRJDUsySR' ? <Analytics /> : <Navigate to="/admin" />} />
      <Route path="*" element={<h1>404</h1>} />
    </Routes>
  );
}



