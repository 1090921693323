import { useEffect, useState } from "react";
import Layout from "./Layout";
import { create, read, remove, suspend_continue, update as modify } from "../../constants/apis/Organizations";

import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import EditModal from "./EditModal";

export default function Organizations() {

    //TODO
    //Validate email and password before submit
    const [data, setData] = useState({ name: '', logo: '', quote: '1', email: '', password: '' })
    const [update, setUpdate] = useState({ name: '', logo: '', quote: '1' })
    const [organizations, setOrganizations] = useState([])
    const [trigger, setTrigger] = useState(1)
    const [isDeleteModalOpen, setisDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setisEditModalOpen] = useState(false);
    const [selected, setSelected] = useState("");

    const handleOnInputChange = (event) => {
        setData((previous) => ({ ...previous, [event.target.name]: event.target.value }))
    }

    const handleOnCreateOrganization = async () => {
        create(data).then((response) => {
            setTrigger((previous) => previous + 1)
            toast.success("Organization Created Succesfully", { transition: Slide, })
            setData({ name: '', logo: '', quote: '1', email: '', password: '' })
            console.log('response', response)
        }).catch((error) => {
            toast.error(`Failed to create organization ${error.messsage}`, { transition: Slide, })
            console.log('error', error)
        })
    }

    const handleOnSuspendOrganization = async (id) => {
        suspend_continue(id).then((response) => {
            setTrigger((previous) => previous + 1)
            console.log('response', response)
        }).catch((error) => {
            toast.error(`Failed to suspend organization ${error.messsage}`, { transition: Slide, })
            console.log('error', error)
        })
    }


    const handleOnRemoveOrganization = async (id) => {
        remove(id).then((response) => {
            setSelected('')
            setisDeleteModalOpen(false)
            toast.success("Organization Deleted Succesfully", { transition: Slide, })
            setTrigger((previous) => previous + 1)
            console.log('response', response)
        }).catch((error) => {
            setSelected('')
            setisDeleteModalOpen(false)
            toast.error(`Failed to delete organization ${error.messsage}`, { transition: Slide, })
            console.log('error', error)
        })
    }


    const handleOnUpdateOrganization = (id, body) => {
        modify(id, body).then((response) => {
            console.log('response', response)
            setisEditModalOpen(false)
            toast.success("Organization Updated Succesfully", { transition: Slide, })
            setSelected('')
            setUpdate({ name: '', logo: '', quote: '1' })
            setTrigger((previous) => previous + 1)
        }).catch((error) => {
            setisEditModalOpen(false)
            setSelected('')
            setUpdate({ name: '', logo: '', quote: '1' })
            console.log('error', error)
        })
    }

    useEffect(() => {
        read().then((response) => {
            setOrganizations(response.length > 0 ? response : [])
            console.log('response', response)
        }).catch((error) => {
            console.log('error', error)
        })
    }, [trigger])


    return (
        <Layout header="Organizations" >

            <div className="h-4"></div>


            {/*Form*/}
            <div className="p-10 bg-white m-10 border rounded-lg ">
                <div className="max-w-md mb-5">
                    <label className="text-sm text-[#5E6366]">
                        Name
                    </label>
                    <div className="flex bg-[#EFF1F9] rounded-lg mt-2">
                        <input
                            name="name"
                            onChange={handleOnInputChange}
                            value={data.name}
                            className="bg-[#EFF1F9] w-full py-3 pl-3 outline-none rounded-lg"
                            placeholder="Enter tenant name here..."
                        />
                    </div>
                </div>

                <div className="flex space-x-10 mb-5">
                    <div className="grow max-w-md">
                        <label className="text-sm text-[#5E6366]">
                            Email
                        </label>
                        <div className="flex bg-[#EFF1F9] rounded-lg mt-2">
                            <input
                                name="email"
                                onChange={handleOnInputChange}
                                value={data.email}
                                className="bg-[#EFF1F9] w-full py-3 pl-3 outline-none rounded-lg"
                                placeholder="Enter admin email here..."
                            />
                        </div>
                    </div>

                    <div className="grow max-w-md">
                        <label className="text-sm text-[#5E6366]">
                            Password
                        </label>
                        <div className="flex bg-[#EFF1F9] rounded-lg mt-2">
                            <input
                                type="password"
                                name="password"
                                value={data.password}
                                onChange={handleOnInputChange}
                                className="bg-[#EFF1F9] w-full py-3 pl-3 outline-none rounded-lg"
                                placeholder="Enter admin password here..."
                            />
                        </div>
                    </div>
                </div>

                <div className="flex space-x-10 mb-5">
                    <div>
                        <label className="text-sm text-[#5E6366]">
                            Quote
                        </label>
                        <div className="flex bg-[#EFF1F9] rounded-lg mt-2">
                            <input
                                type="number"
                                name="quote"
                                value={data.quote}
                                onChange={handleOnInputChange}
                                className="bg-[#EFF1F9] w-full py-3 pl-3 outline-none rounded-lg"
                                placeholder="Enter tenant quote here..."
                            />
                        </div>
                    </div>
                    <div className="grow">
                        <label className="text-sm text-[#5E6366]">Logo</label>
                        <div className="flex bg-[#EFF1F9] rounded-lg mt-2">
                            <input
                                name="logo"
                                value={data.logo}
                                onChange={handleOnInputChange}
                                className="bg-[#EFF1F9] w-full py-3 pl-3 outline-none rounded-lg"
                                placeholder="Enter logo here..."
                            />
                        </div>
                    </div>

                </div>

                <button className="bg-primary  text-white text-sm rounded-lg px-12 py-2 " onClick={() => handleOnCreateOrganization()}>
                    Create
                </button>
            </div>

            {/*Table*/}
            <div className="p-10 bg-white m-10 border rounded-lg ">

                <div className="">
                    <table className="w-full  text-left table-auto min-w-max text-center">
                        <thead>
                            <tr>
                                <th className="p-4 border-y border-blue-gray-100 bg-blue-gray-50/50 text-center w-10 ">
                                    <p className="block font-sans text-sm antialiased font-bold leading-none text-blue-gray-900 opacity-70 ">
                                        #
                                    </p>
                                </th>

                                <th className="p-4 border-y border-blue-gray-100 bg-blue-gray-50/50 text-center ">
                                    <p className="block font-sans text-sm antialiased font-bold leading-none text-blue-gray-900 opacity-70 ">
                                        Organization
                                    </p>
                                </th>

                                <th className="p-4 border-y border-blue-gray-100 bg-blue-gray-50/50 text-center">
                                    <p className="block font-sans text-sm antialiased font-bold leading-none text-blue-gray-900 opacity-70">
                                        Quote
                                    </p>
                                </th>

                                <th className="p-4 border-y border-blue-gray-100 bg-blue-gray-50/50 text-center">
                                    <p className="block font-sans text-sm antialiased font-bold leading-none text-blue-gray-900 opacity-70">
                                        Date
                                    </p>
                                </th>
                                <th className="p-4 border-y border-blue-gray-100 bg-blue-gray-50/50 text-center">
                                    <p className="block font-sans text-sm antialiased font-bold leading-none text-blue-gray-900 opacity-70">
                                        Action
                                    </p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {organizations.map((organization, index) => {

                                return (
                                    <tr key={index} className={`${organization.suspend && 'bg-gray-200'}`}>
                                        <td className="p-4 border-b border-blue-gray-50 text-center">
                                            <p className="block font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                                                {index + 1}
                                            </p>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50 text-center">
                                            <p className="block font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                                                {organization.name}
                                            </p>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50 text-center">
                                            <p className="block font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                                                {organization.quote}
                                            </p>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50 text-center">
                                            <p className="block font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                                                {organization.created_on}
                                            </p>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50 text-center flex  gap-2 justify-center">
                                            <button className={`text-white text-sm  border px-4 py-1 rounded-lg ${organization.suspend ? 'bg-gray-500' : 'bg-gray-400'}`} onClick={() => handleOnSuspendOrganization(organization._id)}>
                                                {organization.suspend ? 'Activate' : 'Suspend'}
                                            </button>

                                            <button className={`text-white text-sm  border px-4 py-1 rounded-lg bg-primary`}
                                                onClick={() => {
                                                    setSelected(organization._id);
                                                    setUpdate({ name: organization.name, logo: organization.logo, quote: organization.quote })
                                                    setisEditModalOpen(true);
                                                }}>
                                                Edit
                                            </button>
                                            <button className={`text-white text-sm  border px-4 py-1 rounded-lg bg-red-500`}
                                                onClick={() => {
                                                    setSelected(organization._id);
                                                    setisDeleteModalOpen(true);
                                                }}>
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
            </div>


            <ConfirmationModal
                title="Organization"
                open={isDeleteModalOpen}
                setOpen={setisDeleteModalOpen}
                func={() => handleOnRemoveOrganization(selected)}
            />

            <EditModal
                title="Organization"
                open={isEditModalOpen}
                setOpen={setisEditModalOpen}
                update={update}
                setUpdate={setUpdate}
                func={() => handleOnUpdateOrganization(selected, update)}
            />

            <ToastContainer />
        </Layout>

    )
}