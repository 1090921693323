import { useState, useEffect, useContext } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import Layout from "../components/common/Layout";
import { ReactComponent as UserStar } from "../assets/icons/UserStar.svg";
import { ReactComponent as XCircle } from "../assets/icons/XCircle.svg";
import { ReactComponent as MailTime } from "../assets/icons/MailTime.svg";
import { ReactComponent as MailRoundArrow } from "../assets/icons/MailRoundArrow.svg";
import { ReactComponent as Sparkles } from "../assets/icons/Sparkles.svg";
import { getAllDashboardStatsAPI } from "../services/apis/Dashboard";
import ReactWordcloud from "react-wordcloud";
import { AuthContext } from "../store/authentication";
import { readOne } from "../constants/apis/Organizations";
import { formatDateDDMMYY } from "../utils/formatting";
import DatePicker from "react-datepicker";
import { constructQueryParams } from "../utils/encodeURI";

// Register the necessary chart.js components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

export default function Dashboard() {
  const { profile } = useContext(AuthContext)
  const [stats, setStats] = useState([]);
  const [sessions, setSessions] = useState(null);
  const [words, setWords] = useState([])
  const [filters, setFilters] = useState({ gte: '', lte: '' })
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleOnDateChange = (dates) => {
    console.log('dates', dates)
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      setFilters((previous) => {
        return {
          ...previous,
          gte: formatDateDDMMYY(start),
          lte: formatDateDDMMYY(end)
        }
      })
    }
  }



  useEffect(() => {
    get_all_stats();
    readOrganization()
  }, [filters]);

  useEffect(() => {
    readOrganization()
  }, []);

  async function readOrganization() {
    readOne(profile.organization._id).then((response) => {
      console.log('response', response)
      setWords(response.frequent_keywords ? response.frequent_keywords : [])
    }).catch((error) => {
      console.log('error', error)
      setWords([])
    })
  }
  async function get_all_stats() {
    try {
      const query = constructQueryParams(filters)
      const res = await getAllDashboardStatsAPI(profile.organization._id, query);
      const data = res.data.result[0];

      console.log('data', data, 'filters', filters, data.total_request)
      // Transform stats for display in Doughnut and summary
      const customRes = [
        {
          icon: MailRoundArrow,
          total: data.total_request ? data.total_request : 0,
          unit: "Requests",
          label: "Num of requests",
          color: "#5570F1",
        },
        {
          icon: MailTime,
          total: data.latency ? data.latency.toFixed(2) : 0,
          unit: "Seconds",
          label: "Response time",
          color: "#97A5EB",
        },
        {
          icon: XCircle,
          total: data.total_failed_request ? data.total_failed_request : 0,
          percent: data.total_failed_request ? (data.total_failed_request / data.total_request) * 100 : 0,
          unit: "Requests",
          label: "Failed Requests",
          color: "#EF3826",
        },
        {
          icon: UserStar,
          total: data.users ? data.users : 0,
          unit: "User",
          label: "Unique users by IP",
          color: "#00B69B",
        },
        {
          icon: Sparkles,
          total: (
            (data.total_request - data.total_failed_request) /
            data.total_request
          ).toFixed(2),
          unit: "Requests",
          label: "Satisfaction rate",
          color: "#97A5EB",
        },
        {
          icon: Sparkles,
          total: 7,
          unit: "conversation",
          label: "Avg messages",
          color: "#97A5EB",
        },
      ];
      setStats(customRes);

      // Transform sessions data for the Bar chart
      const transformedSessions = {
        labels: data.sessions.map((entry) => entry.Date),
        datasets: [
          {
            label: "frequency",
            data: data.sessions.map((entry) => entry.frequency),
            backgroundColor: "rgba(85, 112, 241, 0.5)",
            borderColor: "rgba(85, 112, 241, 1)",
            borderWidth: 1,
          },
        ],
      };
      setSessions(transformedSessions);
    } catch (error) {
      console.log("Error fetching stats:", error);
    }
  }

  return (
    <Layout header="Dashboard">
      <div className="p-6">
        {/* CHARTS */}
        <div className="ring-1 ring-gray-200 sm:mx-0 rounded-lg bg-white p-5">
          <div className="flex justify-between gap-5 items-center">
            <h1>Analytics</h1>
            <div className="flex bg-[#EFF1F9] rounded-lg ">
              <DatePicker dateFormat="dd-MM-yyyy" selectsRange placeholderText="Date Filter" className="bg-[#EFF1F9] w-full p-2   rounded-lg" onChange={handleOnDateChange} startDate={startDate} endDate={endDate} />
            </div>
          </div>


          <div className="flex mt-5">
            <div className="w-[35%]">
              <Doughnut
                data={{
                  labels: stats.filter((item) => (item.label === "Num of requests") || (item.label === "Failed Requests")).map((item) => (item.label === "Failed Requests") ? `${item.label} ${item.percent.toFixed(2)}%` : item.label),
                  datasets: [
                    {
                      data: stats.filter((item) => (item.label === "Num of requests") || (item.label === "Failed Requests")).map((item) => (item.total)),
                      backgroundColor: stats.filter((item) => (item.label === "Num of requests") || (item.label === "Failed Requests")).map((item) => item.color),
                      borderColor: "transparent",
                      borderWidth: 0,
                      hoverOffset: 4,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  cutout: "70%",
                  plugins: {
                    legend: {
                      labels: {
                        boxWidth: 12,
                        padding: 20,
                        usePointStyle: true,
                        pointStyle: "circle",
                      },

                    },

                  },

                }}
                width={300}
                height={300}
              />
            </div>

            <div className="grow grid grid-cols-6 gap-4">
              {stats.map((item) => (
                <div className="flex flex-col h-full" key={item.label}>
                  <div
                    style={{ backgroundColor: item.color }}
                    className="text-white rounded-xl p-5 mb-5 flex-grow flex flex-col justify-center text-center"
                  >
                    <item.icon className="mb-auto mx-auto mt-5" />
                    <h1 className="font-bold text-4xl">{item.total}</h1>
                    <h4 className="font-thin">{item.unit}</h4>
                  </div>
                  <div
                    style={{ backgroundColor: item.color, opacity: "80%" }}
                    className="text-white rounded-xl p-2 text-center text-lg"
                  >
                    {item.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <br />
        <div className="ring-1 ring-gray-200 sm:mx-0 rounded-lg bg-white p-5">
          <div className="grid grid-cols-2 gap-5">
            <div>
              <h1 className="mb-5">Bar Chart</h1>
              {sessions && (
                <Bar
                  data={sessions}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: { position: "top" },
                      title: { display: true, text: "Session Frequency" },
                    },
                    scales: { y: { beginAtZero: true } },
                  }}
                />
              )}
            </div>
            {words.length > 0 &&
              <div>
                <h1 className="mb-5">Word Cloud Chart</h1>
                <div>
                  <ReactWordcloud words={words} />
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </Layout>
  );
}


// const words = [
//   {
//     text: "سيرة ذاتية",
//     value: 300,
//   },
//   {
//     text: "سلام عليكم",
//     value: 11,
//   },
//   {
//     text: "تعيين موظفين غير سعوديين",
//     value: 16,
//   },
//   {
//     text: "مرحبا",
//     value: 17,
//   },
//   {
//     text: "VISA",
//     value: 200,
//   },
//   {
//     text: "contract",
//     value: 11,
//   },
//   {
//     text: "تأشيرات العمل",
//     value: 64,
//   },
//   {
//     text: "authenticate contract",
//     value: 17,
//   },
//   {
//     text: "بقفل منشأة",
//     value: 11,
//   },
//   {
//     text: "مكتب العمل",
//     value: 30,
//   },
//   {
//     text: "establishing",
//     value: 5,
//   },
//   {
//     text: "non-Saudi employees",
//     value: 40,
//   },
//   {
//     text: "hire employees",
//     value: 20,
//   },
//   {
//     text: "أوثق عقد العمل",
//     value: 50,
//   },
//   {
//     text: "تاشيرة موسمي	",
//     value: 60,
//   },
//   {
//     text: "شهادة توطين",
//     value: 70,
//   },
//   {
//     text: "كيف أجدد العقد؟",
//     value: 50,
//   },
//   {
//     text: "الشكوى",
//     value: 100,
//   },
//   {
//     text: "الغاء حسابي",
//     value: 50,
//   },
//   {
//     text: "فسخ عقدي بدون سبب مشروع",
//     value: 100,
//   },
//   {
//     text: " استقطب موظفين غير سعوديين",
//     value: 100,
//   },
//   {
//     text: "افصح عن التدريب",
//     value: 100,
//   },
//   {
//     text: "create my cv on qiwa",
//     value: 100,
//   },
//   {
//     text: "كيف أوثق عقد العمل؟",
//     value: 100,
//   },
//   {
//     text: "Transfer",
//     value: 100,
//   },
//   {
//     text: "كيف أنشئ سيرة ذاتية على منصة قوى	",
//     value: 100,
//   },
//   {
//     text: "find work",
//     value: 100,
//   },
//   {
//     text: "قوى",
//     value: 500,
//   },
//   {
//     text: "Saudi",
//     value: 500,
//   },
//   {
//     text: "كيف اشترك بقوى؟",
//     value: 100,
//   },
//   {
//     text: "نطاق المنشاة",
//     value: 300,
//   },
//   {
//     text: "أوثق",
//     value: 100,
//   },
//   {
//     text: "استقطب",
//     value: 100,
//   },
//   {
//     text: "لمنشأتي",
//     value: 100,
//   },
//   {
//     text: "مستخدم جديد",
//     value: 100,
//   },
//   {
//     text: "موظف سعودي",
//     value: 300,
//   },
//   {
//     text: "رصيد الاستقطاب",
//     value: 300,
//   },
//   {
//     text: "نقل الكفالة",
//     value: 400,
//   },
//   {
//     text: "عقد",
//     value: 400,
//   },
//   {
//     text: "تاشيرة",
//     value: 500,
//   },
//   {
//     text: "work visa",
//     value: 400,
//   },
//   {
//     text: "موظفين",
//     value: 600,
//   },
//   {
//     text: "أوثق عقد العمل",
//     value: 400,
//   },
//   {
//     text: "Saudi employees",
//     value: 400,
//   },
//   {
//     text: "توظيف",
//     value: 500,
//   },
//   {
//     text: "المملكة",
//     value: 400,
//   },
//   {
//     text: "المملكة العربية السعودية",
//     value: 700,
//   },
//   {
//     text: "transfer employees",
//     value: 300,
//   },
//   {
//     text: "قلي عن قوى	",
//     value: 400,
//   },
//   {
//     text: "Qiwa account",
//     value: 400,
//   },
//   {
//     text: "من أنت",
//     value: 400,
//   },
//   {
//     text: "كيفية الاعتراض على مخالفة المنشأة؟	",
//     value: 400,
//   },
//   {
//     text: "كيف يمكن تحميل فواتير عمليات الدفع؟	",
//     value: 300,
//   },
//   {
//     text: "المدفوعات",
//     value: 400,
//   },
//   {
//     text: "طباعة فواتير",
//     value: 500,
//   },
//   {
//     text: "تصدير سجل المدفوعات",
//     value: 400,
//   },
// ];